import {
  useState,
  SetStateAction,
  Dispatch,
  ReactNode,
  useCallback,
} from "react";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SearchIcon from "@mui/icons-material/Search";
import { useDarkMode } from "../../ThemeContext";
import { ColumnsBudget } from "./BudgetKanban/TypeKanbanBudget";
import { ColumnsPurchase } from "./PurchaseKanban/TypeKanbanPurchase";

interface KanbanProps<T> {
  onDragEndHandler: Function;
  renderItems: Function;
  setSelectedItem: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  items: T[];
  setItems:
    | Dispatch<SetStateAction<T[]>>
    | ((updatedItems: T[]) => Promise<void>);
  renderModal: () => ReactNode;
  loading: boolean;
  getColumnBackgroundColor: any;
  columns: ColumnsBudget | ColumnsPurchase;
  setColumns:
    | Dispatch<SetStateAction<ColumnsBudget>>
    | Dispatch<SetStateAction<ColumnsPurchase>>;
}

export default function Kanban<T>({
  onDragEndHandler,
  renderItems,
  setSelectedItem,
  open,
  setOpen,
  items,
  setItems,
  renderModal,
  loading,
  getColumnBackgroundColor,
  columns,
  setColumns,
}: KanbanProps<T>) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { isDarkMode } = useDarkMode();

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    []
  );

  const handleOpen = (task: any) => {
    if (!open) {
      setSelectedItem(task);
      setOpen(true);
    }
  };

  const getFilteredTasks = useCallback(
    (tasks: any[]) => {
      return tasks.filter((task) => {
        const searchInFields = [
          task.carId,
          task.company,
          task.id,
          task.brandCar,
          task.modelCar,
          ...(task.tags ? task.tags.map((tag: any) => tag.title) : []),
          task.name,
          task.lastName,
          task.priorityName,
          task.workshopName,
          task.in_workshop === 1 ? "En Taller" : "Fuera del Taller",
          task.lastModificationDate,
        ];

        return searchInFields.some((field) =>
          field
            ? field.toString().toLowerCase().includes(searchTerm.toLowerCase())
            : false
        );
      });
    },
    [searchTerm]
  );

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <TextField
            variant="outlined"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearch}
            sx={{ mb: 2 }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              transition: "all 700ms",
              overflow: "Hidden",
            }}
          >
            <DragDropContext
              onDragEnd={(result: any) => {
                onDragEndHandler(result, columns, setColumns, setItems, items);
              }}
            >
              {renderModal()}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  overflow: "auto",
                  maxHeight: "80vh",
                }}
              >
                {Object.entries(columns).map(([columnId, column]: any) => {
                  const filteredTasks = getFilteredTasks(column.items);

                  return (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "8px",
                        background: "red",
                        borderRadius: "10px",
                        margin: "0px 16px 10px 0px",
                        backgroundColor: getColumnBackgroundColor(column.id),
                      }}
                      key={columnId}
                    >
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided: any) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              minWidth: { xs: "250px", md: "290px" },
                              gap: 3,
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "5px",
                                width: "100%",
                                backgroundColor: isDarkMode
                                  ? "#2E363E"
                                  : "white",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                fontWeight: "medium",
                              }}
                            >
                              <Box>{column.name}</Box>
                              <Box
                                sx={{
                                  marginLeft: "5px",
                                  fontSize: ".8rem",
                                }}
                              >
                                {searchTerm
                                  ? `${filteredTasks.length} de ${column.items.length}`
                                  : `${column.items.length}`}
                              </Box>
                            </Box>
                            {filteredTasks.map((task: any, index: number) => {
                              const handleTaskOpen = () => handleOpen(task);
                              const taskId = Array.isArray(task)
                                ? task[0].id.toString()
                                : task.id.toString();

                              return (
                                <Draggable
                                  key={taskId}
                                  draggableId={taskId}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <Box>
                                      {renderItems(
                                        provided,
                                        task,
                                        handleTaskOpen
                                      )}
                                    </Box>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </Box>
                  );
                })}
              </Box>
            </DragDropContext>
          </Box>
        </Box>
      )}
    </>
  );
}
