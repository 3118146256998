import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Chip,
  Button,
  Stack,
  CircularProgress,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useMemo, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { UserService } from "../../../services/UserService";
import { WorkshopService } from "../../../services/WorkshopService";
import { User } from "../../../entities/user.entity";
import { enqueueSnackbar } from "notistack";
import { PermissionEnum } from "../../../enum/permission.enum";
import { useAppSelector } from "../../../redux-store/hooks";
import { Budget } from "../../../entities/budget.entity";
import { BudgetContext } from "./contexBudget/BudgetContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UserAssignment({ budget }: { budget: Budget }) {
  const [rows, setRows] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedMechanicIds, setSelectedMechanicIds] = useState<number[]>([]);
  const [mechanics, setMechanics] = useState<
    { id: number; fullname: string }[]
  >([]);

  const [loading, setLoading] = useState(true);
  const workshopService = useMemo(() => new WorkshopService(), []);
  const userService = useMemo(() => new UserService(), []);
  const user = useAppSelector((state) => state.userReducer.user);

  useEffect(() => {
    const fetchMechanics = async () => {
      try {
        if (budget?.workshop.id) {
          const mechanics: number[] = await userService.getMechanics();

          const users: User[] = await workshopService.users(
            budget?.workshop.id
          );

          const mechanicUsers = users.filter((u: User) =>
            mechanics.includes(u.id)
          );

          const mechanicsData = mechanicUsers.map((u: User) => ({
            id: u.id,
            fullname: u.fullname,
          }));

          setMechanics(mechanicsData);
        }
      } catch (error) {
        console.error("Failed to fetch users or mechanics:", error);
        enqueueSnackbar("Failed to fetch mechanics", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    void fetchMechanics();
  }, [budget, userService, workshopService]);

  useEffect(() => {
    setSelectedMechanicIds(
      budget.budgetMechanics.map((mechanic) => mechanic.user.id)
    );
  }, [budget]);

  useEffect(() => {
    const newRows = selectedMechanicIds
      .map((id, index) => {
        const mechanic = mechanics.find((m) => m.id === id);
        return mechanic ? { id: index + 1, name: mechanic.fullname } : null;
      })
      .filter(Boolean) as { id: number; name: string }[];
    setRows(newRows);
  }, [mechanics, selectedMechanicIds]);

  const budgetContext = useContext(BudgetContext);

  const updateMechanicsWithFeedback = (mechanicIds: number[]) => {
    if (budget) {
      budgetContext
        .updateMechanics(budget.id, mechanicIds)
        .then(() => {
          enqueueSnackbar("Asignación actualizada", { variant: "success" });
        })
        .catch((error) => {
          console.error(
            "Error al actualizar la asignación de mecánicos:",
            budget.id,
            mechanicIds,
            error
          );
          enqueueSnackbar("Error al actualizar la asignación de mecánicos", {
            variant: "error",
          });
        });
    }
  };

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const { value } = event.target;
    const selectedIds =
      typeof value === "string" ? value.split(",").map(Number) : value;
    setSelectedMechanicIds(selectedIds);
    updateMechanicsWithFeedback(selectedIds);
  };

  const handleDelete = () => {
    setSelectedMechanicIds([]);
    updateMechanicsWithFeedback([]);
  };

  const handleRemove = (idToRemove: number) => {
    const updatedIds = selectedMechanicIds.filter((id) => id !== idToRemove);
    setSelectedMechanicIds(updatedIds);
    updateMechanicsWithFeedback(updatedIds);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Mecánico asignado", width: 150 },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredNames = rows.filter((row) =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const canUpdateBudgetWorkshop = !user.permissions.includes(PermissionEnum.BUDGET_WORKSHOP_UPDATE);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridTemplateRows: "auto auto auto",
        gridTemplateAreas: `"select search" "counter counter" "chip chip"  "tab tab" `,
        gap: selectedMechanicIds.length > 0 ? 2 : 1,
        marginTop: "15px",
      }}
    >
      <Box sx={{ gridArea: "select" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "250px",
              justifyContent: "space-evenly",
            }}
          >
            <Typography>Asignar mecánico</Typography>
            <CircularProgress size={24} style={{ marginTop: 8 }} />
          </Box>
        ) : (
          <FormControl size="small" sx={{ width: 300 }}>
            <InputLabel size="small" id="demo-multiple-chip-label">
              Asignar mecanico
            </InputLabel>
            <Select
              multiple
              value={selectedMechanicIds}
              onChange={handleChange}
              input={<OutlinedInput label="Asignar mecánico" />}
              renderValue={(selectedIds) => {
                const selectedMechanics = selectedIds
                  .map((id) => {
                    const mechanic = mechanics.find((m) => m.id === id);
                    return mechanic ? mechanic.fullname : "";
                  })
                  .filter(Boolean);
                return selectedMechanics.join(", ");
              }}
              MenuProps={MenuProps}
              disabled={canUpdateBudgetWorkshop}
            >
              {mechanics.map((mechanic) => (
                <MenuItem key={mechanic.id} value={mechanic.id}>
                  <Checkbox
                    checked={selectedMechanicIds.indexOf(mechanic.id) > -1}
                  />
                  <ListItemText primary={mechanic.fullname} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <TextField
        id="outlined-basic"
        label="Buscar"
        variant="outlined"
        type="search"
        size="small"
        sx={{ gridArea: "search" }}
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {selectedMechanicIds.length > 0 && (
        <>
          <Stack direction="row" spacing={1} sx={{ gridArea: "counter" }}>
            <Box sx={{ gridArea: "counter", typography: "subtitle2" }}>
              {selectedMechanicIds.length}
            </Box>
            <Box sx={{ typography: "body2" }}>asignaciones</Box>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ gridArea: "chip", display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                border: "dashed 1px rgb(145 158 171 / 20%)",
                p: 0.5,
                borderRadius: "5px",
                minWidth: 300,
                minHeight: 34,
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selectedMechanicIds.map((id) => {
                  const mechanic = mechanics.find((m) => m.id === id);
                  return (
                    mechanic && (
                      <Chip
                        key={id}
                        label={mechanic.fullname}
                        onDelete={() => handleRemove(id)}
                        size="small"
                        disabled={canUpdateBudgetWorkshop}
                      />
                    )
                  );
                })}
              </Box>
            </Box>
            <Button
              onClick={handleDelete}
              size="small"
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ border: 0, "&:hover": { border: 0 } }}
              disabled={canUpdateBudgetWorkshop}
            >
              Desasignar todo
            </Button>
          </Stack>
        </>
      )}
      <Box sx={{ minHeight: 300, width: "100%", gridArea: "tab" }}>
        <DataGrid
          rows={filteredNames}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>
    </Box>
  );
}
