import { Box, Modal } from "@mui/material";
import ModalDetailBudget from "./ModalDetailBudget";
import { useContext } from "react";
import { BudgetContext } from "./contexBudget/BudgetContext";
import { Budget } from "../../../entities/budget.entity";

const BudgetModal = () => {
  const {
    open,
    setOpen,
    updateBudget,
    selectedBudgetData,
    setSelectedBudgetData,
  } = useContext(BudgetContext);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "white",
          margin: "auto",
          marginTop: "4%",
          marginBottom: "4%",
          height: "85%",
          boxShadow: 24,
          width: "80%",
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: "8px",
        }}
      >
        {selectedBudgetData && (
          <ModalDetailBudget
            selectedBudgetData={selectedBudgetData}
            handleClose={handleClose}
            onBudgetChange={(updatedBudget: Budget) => {
              updateBudget(updatedBudget.id, updatedBudget).then(() => {
                const updatedBudgets = selectedBudgetData.budgets.map((b) =>
                  b.id === updatedBudget.id ? updatedBudget : b
                );
                setSelectedBudgetData({
                  ...selectedBudgetData,
                  budgets: updatedBudgets,
                });
              });
            }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default BudgetModal;
