import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { WorkshopBudgetState } from "../../../entities/workshop-budget-state";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Budget } from "../../../entities/budget.entity";
import { BudgetService } from "../../../services/BudgetService";
import { useAppSelector } from "../../../redux-store/hooks";
import { RootState } from "../../../redux-store";
import { PermissionEnum } from "../../../enum/permission.enum";
import { FieldError } from "../../../types/FieldError";

const styles = {
  select: {
    backgroundColor: "#2e7d32",
    color: "white",
    textAlign: "center" as const,
  },
  rowCart: {
    width: "100%",
    padding: "6px",
  },
};

interface budgetTopPanelProps {
  budget: any;
  onBudgetChange: (budget: Budget) => void;
}

export default function TopPanelBudget({
  budget,
  onBudgetChange,
}: budgetTopPanelProps) {
  const [errors, setErrors] = useState<FieldError[]>();
  const [selectedState, setSelectedState] = useState(
    budget.workshop_budget_state_id
  );

  const budgetService = new BudgetService();
  const user = useAppSelector((state: RootState) => state.userReducer.user);

  useEffect(() => {
    setSelectedState(budget.workshop_budget_state_id);
  }, [budget]);

  const handleUpdateBudgetState = async (event: SelectChangeEvent) => {
    setErrors(undefined);
    const newStateId = Number(event.target.value);

    try {
      const updatedBudget = await budgetService.update(budget.id, {
        workshop_budget_state_id: newStateId,
      });

      onBudgetChange(updatedBudget);
      enqueueSnackbar("Se actualizó el estado del presupuesto", {
        variant: "success",
      });
      setSelectedState(newStateId);
    } catch (error: any) {
      console.error("Error updating budget state:", error);
      setErrors([
        { field: "workshop_budget_state_id", message: error.message },
      ]);
      enqueueSnackbar("Error al actualizar el estado del presupuesto", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateAreas: `
          "auto state"
          "detail detail"
          `,
          gridTemplateColumns: "1fr 0.5fr",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <h2
          style={{ margin: "0px", gridArea: "auto" }}
        >{`${budget.car.identification} ${budget.car.brand.name} ${budget.car.carModel?.name}`}</h2>

        <Box
          sx={{
            minWidth: 120,
            gridArea: "state",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              value={selectedState.toString()}
              onChange={handleUpdateBudgetState}
              style={styles.select}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              size="small"
              disabled={
                !user.permissions.includes(
                  PermissionEnum.BUDGET_WORKSHOP_UPDATE
                )
              }
            >
              <MenuItem value={WorkshopBudgetState.PENDING_ID}>
                {WorkshopBudgetState.PENDING_LABEL}
              </MenuItem>
              <MenuItem value={WorkshopBudgetState.APPOINTMENT_ID}>
                {WorkshopBudgetState.APPOINTMENT_LABEL}
              </MenuItem>
              <MenuItem value={WorkshopBudgetState.ADMITTED_ID}>
                {WorkshopBudgetState.ADMITTED_LABEL}
              </MenuItem>
              <MenuItem value={WorkshopBudgetState.DIAGNOSIS_ID}>
                {WorkshopBudgetState.DIAGNOSIS_LABEL}
              </MenuItem>
              <MenuItem value={WorkshopBudgetState.PURCHASE_REQUESTED_ID}>
                {WorkshopBudgetState.PURCHASE_REQUESTED_LABEL}
              </MenuItem>
              <MenuItem value={WorkshopBudgetState.REPAIR_ID}>
                {WorkshopBudgetState.REPAIR_LABEL}
              </MenuItem>
              <MenuItem value={WorkshopBudgetState.FINISHED_ID}>
                {WorkshopBudgetState.FINISHED_LABEL}
              </MenuItem>
              <MenuItem value={WorkshopBudgetState.DELIVERED_ID}>
                {WorkshopBudgetState.DELIVERED_LABEL}
              </MenuItem>
            </Select>
            {errors &&
              errors.find(
                (e: any) => e.field === "workshop_budget_state_id"
              ) && (
                <FormHelperText error={true}>
                  {
                    errors.find(
                      (e: any) => e.field === "workshop_budget_state_id"
                    )?.message
                  }
                </FormHelperText>
              )}
          </FormControl>
        </Box>

        <Paper sx={{ gridArea: "detail" }}>
          <Box>
            <Table size="small">
              <TableBody sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "6px 6px 6px 16px",
                      }}
                      scope="row"
                    >
                      <strong>Motor:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.engine_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px 6px 16px" }} scope="row">
                      <strong>Chasis:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.chassis_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px 6px 16px" }} scope="row">
                      <strong>Color:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.color}
                    </TableCell>
                  </TableRow>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <TableRow>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", padding: "6px" }}
                      scope="row"
                    >
                      <strong>Cliente:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.company.name}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", padding: "6px" }}
                      scope="row"
                    >
                      <strong>Tipo de servicio:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.engine_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", padding: "6px" }}
                      scope="row"
                    >
                      <strong>Km al solicitar:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.usage}
                    </TableCell>
                  </TableRow>
                </Box>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
