import { ColumnsBudget, TypeKanbanBudget } from "./TypeKanbanBudget";
import { Budget } from "../../../../entities/budget.entity";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopBudgetState } from "../../../../entities/workshop-budget-state";
import {
  getTimeDifferenceInfo,
  parseUTCDate,
} from "../../../../utils/dateUtils";

const stateColumnsMap: { [key: number]: string } = {
  [WorkshopBudgetState.PENDING_ID]: WorkshopBudgetState.PENDING_LABEL,
  [WorkshopBudgetState.APPOINTMENT_ID]: WorkshopBudgetState.APPOINTMENT_LABEL,
  [WorkshopBudgetState.ADMITTED_ID]: WorkshopBudgetState.ADMITTED_LABEL,
  [WorkshopBudgetState.DIAGNOSIS_ID]: WorkshopBudgetState.DIAGNOSIS_LABEL,
  [WorkshopBudgetState.PURCHASE_REQUESTED_ID]:
    WorkshopBudgetState.PURCHASE_REQUESTED_LABEL,
  [WorkshopBudgetState.REPAIR_ID]: WorkshopBudgetState.REPAIR_LABEL,
  [WorkshopBudgetState.FINISHED_ID]: WorkshopBudgetState.FINISHED_LABEL,
  [WorkshopBudgetState.DELIVERED_ID]: WorkshopBudgetState.DELIVERED_LABEL,
};

export const generateColumnsFromBudgets = (
  activeBudgets: Budget[],
  purchases: WorkshopPurchase[]
): ColumnsBudget => {
  const columns: ColumnsBudget = {};

  const columnOrder = [
    WorkshopBudgetState.PENDING_ID,
    WorkshopBudgetState.APPOINTMENT_ID,
    WorkshopBudgetState.ADMITTED_ID,
    WorkshopBudgetState.DIAGNOSIS_ID,
    WorkshopBudgetState.PURCHASE_REQUESTED_ID,
    WorkshopBudgetState.REPAIR_ID,
    WorkshopBudgetState.FINISHED_ID,
    WorkshopBudgetState.DELIVERED_ID,
  ];

  const stateColors = {
    bg: "#cffafe",
    text: "#0891b2",
  };

  columnOrder.forEach((stateId) => {
    const stateName = stateColumnsMap[stateId];
    columns[stateName] = {
      id: stateId,
      name: stateName,
      items: [],
      color: stateColors,
    };
  });

  const groupedBudgets = new Map<string, TypeKanbanBudget>();

  activeBudgets.forEach((budget) => {
    const relatedPurchase = purchases.find(
      (purchase) => purchase.budget.id === budget.id
    );

    const lastModificationDate = budget.last_state_change_date;

    const { formattedHoverDate, formattedDate, background, color } =
      getTimeDifferenceInfo(lastModificationDate);

    const stateName = stateColumnsMap[budget.workshop_budget_state_id ?? 1];
    const budgetArrivalDate = parseUTCDate(budget.workshop_arrival_date);
    const budgetExitDate = parseUTCDate(budget.workshop_exit_date);

    const groupKey = `${budget.car.identification}-${budget.appointment_date}-${stateName}`;

    let newGroup: TypeKanbanBudget = [];

    if (groupedBudgets.has(groupKey)) {
      newGroup = groupedBudgets.get(groupKey) || [];
    }

    newGroup.push({
      ...budget,
      id: budget.id,
      carId: budget.car.identification,
      brandCar: budget.car.brand.name.toString(),
      modelCar: budget.car.carModel?.name.toString(),
      company: budget.company.name,
      description: budget.description,
      nameCreate: budget.createdBy.name,
      lastnameCreate: budget.createdBy.lastname,
      in_workshop: budget.in_workshop,
      appointment: budget.appointment_date,
      purchase: relatedPurchase || null,
      purchases: relatedPurchase ? [relatedPurchase] : [],
      tags: [
        {
          budgetId: budget.id,
          title: `PR ${budget.id.toString()} - ${
            budget.budgetState.client_facing_name
          }`,
          ...columns[stateName].color,
          PurchaseState: `SC ${relatedPurchase?.workshopPurchaseState.name}`,
        },
      ],
      creation_date: budget.creation_date,
      expiration_days: budget.expiration_days,
      workshop_budget_state_id: budget.workshop_budget_state_id || null,
      workshop_arrival_date: budgetArrivalDate,
      workshop_exit_date: budgetExitDate,
      workshopName: budget.workshop.name,
      lastModificationHoverDate: formattedHoverDate,
      lastModificationFormattedDate: formattedDate,
      lastModificationBg: background,
      lastModificationColor: color,
      budgetState: budget.budgetState,
      createdBy: budget.createdBy,
    });

    groupedBudgets.set(groupKey, newGroup);
  });

  groupedBudgets.forEach((taskGroup) => {
    const stateName =
      stateColumnsMap[taskGroup[0].workshop_budget_state_id ?? 1];
    columns[stateName].items.push(taskGroup);
  });

  return columns;
};
