import { Route, Routes } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import Index from "./Pages/Purchase/Index";
import Report from "./Pages/Purchase/Report";
import PurchaseDetail from "./Pages/Purchase/Detail";
import { useAppSelector } from "../redux-store/hooks";
import { PermissionEnum } from "../enum/permission.enum";
import BudgetIndex from "./Pages/Budget/BudgetIndex";
import { PurchaseProvider } from "./Pages/Purchase/context/PurchaseProvider";
import { BudgetProvider } from "./Pages/Budget/contexBudget/BudgetProvider";
import Calendar from "./Pages/Calendar/Calendar";
import CombinedProvider from "./Pages/Budget/contexBudget/CombinedProvider ";
import StockIndex from "./Pages/Stock/StockIndex";
import MechanicsIndex from "./Pages/Mechanics/MechanicsIndex";
import { RootState } from "../redux-store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface RouteInterface {
  path: string;
  element: any;
}

export enum RoutePath {
  INDEX = "/",
  BUDGET = "/presupuestos",
  REPORT = "/reporte-de-compras",
  SHOW_PURCHASE = "/compras/:id",
  CALENDAR = "/calendario",
  STOCK = "/stock",
  MECHANICS = "/mecanicos",
}

export default function AppRoutes() {
  const user = useAppSelector((state: RootState) => state.userReducer.user);
  const navigate = useNavigate();

  const routes: RouteInterface[] = [
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  if (user.permissions.includes(PermissionEnum.WORKSHOP_PURCHASE_UPDATE)) {
    routes.push({
      path: RoutePath.INDEX,
      element: (
        <PurchaseProvider>
          <Index />
        </PurchaseProvider>
      ),
    });

    routes.push({
      path: RoutePath.SHOW_PURCHASE,
      element: <PurchaseDetail />,
    });

    routes.push({
      path: RoutePath.REPORT,
      element: (
        <PurchaseProvider>
          <Report />
        </PurchaseProvider>
      ),
    });

    routes.push({
      path: RoutePath.STOCK,
      element: <StockIndex />,
    });
  }

  if (user.permissions.includes(PermissionEnum.BUDGET_WORKSHOP_INDEX)) {
    routes.push({
      path: RoutePath.BUDGET,
      element: (
        <CombinedProvider>
          <BudgetIndex />
        </CombinedProvider>
      ),
    });
    routes.push({
      path: RoutePath.CALENDAR,
      element: (
        <CombinedProvider>
          <Calendar />
        </CombinedProvider>
      ),
    });

    if (!user.permissions.includes(PermissionEnum.WORKSHOP_PURCHASE_INDEX)) {
      routes.push({
        path: RoutePath.INDEX,
        element: (
          <BudgetProvider>
            <BudgetIndex />
          </BudgetProvider>
        ),
      });
    }
  }

  if (user.permissions.includes(PermissionEnum.USER_WORK_INDEX)) {
    routes.push({
      path: RoutePath.MECHANICS,
      element: <MechanicsIndex />,
    });
  }

  useEffect(() => {
    if (user && user.id) {
      const firstLink = routes[1]?.path;
      navigate(firstLink);
    }
  }, [user]);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}
