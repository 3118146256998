import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import { Box, TextField } from "@mui/material";
import "../../../styles/Mui/index";
import { BudgetContext } from "../Budget/contexBudget/BudgetContext";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { Car } from "../../../entities/car.entity";
import { format } from "date-fns";
import { useDarkMode } from "../../ThemeContext";
import { EventClickArg } from "@fullcalendar/core/index.js";
import BudgetModal from "../Budget/BudgetModal";

interface CalendarEvent {
  id: string;
  title: string;
  start: string;
  end?: string;
  description?: string;
}

const createEvent = (budget: any) => {
  const turnDate = new Date(budget.appointment_date + "Z");
  const formattedDate = format(turnDate, "yyyy-MM-dd'T'HH:mm:ssXXX");

  return {
    id: budget.id.toString(),
    title: `${Car.getSummary(budget.car)} / ID: ${budget.id} / ${
      budget.company.name
    }`,
    start: formattedDate,
    description: `ID: ${budget.id}<br>${budget.company.name}<br>Descripción: ${budget.description}`,
  };
};

const Calendar: React.FC = () => {
  const { pagedBudgets, setSelectedBudgetData, setOpen } =
    useContext(BudgetContext);

  const allEvents = useMemo(() => {
    return pagedBudgets
      .filter((budget: any) => budget.appointment_date !== null)
      .map(createEvent);
  }, [pagedBudgets]);

  const [searchTerm, setSearchTerm] = useState("");
  const [events, setEvents] = useState<CalendarEvent[]>(allEvents);
  const { isDarkMode } = useDarkMode();

  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventId = clickInfo.event.id;
    console.log("eventId: ", eventId);
    const selectedItemCard = pagedBudgets.find(
      (item) => item.id.toString() === eventId
    );

    if (selectedItemCard) {
      setSelectedBudgetData({ budgets: [selectedItemCard] });
      setOpen(true);
    }
  };

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const term = e.target.value;
      setSearchTerm(term);

      if (term) {
        const filtered = allEvents.filter(
          (event) =>
            event.title.toLowerCase().includes(term.toLowerCase()) ||
            (event.description &&
              event.description.toLowerCase().includes(term.toLowerCase()))
        );
        setEvents(filtered);
      } else {
        setEvents(allEvents);
      }
    },
    [allEvents]
  );

  useEffect(() => {
    setEvents(allEvents);
  }, [allEvents]);

  return (
    <>
      <Box
        className={isDarkMode ? "dark-mode" : ""}
        sx={{
          padding: "16px",
        }}
      >
        <TextField
          size="small"
          label="Buscar turno"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ marginBottom: "16px" }}
        />
        <FullCalendar
          eventColor={isDarkMode ? "#407AD6" : "#D61432"}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          initialView="dayGridMonth"
          locales={[esLocale]}
          locale="es"
          events={events}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          editable={false}
          eventDidMount={(info) => {
            tippy(info.el, {
              content: info.event.extendedProps.description || "",
              placement: "top",
              trigger: "mouseenter",
              animation: "fade",
              theme: "material",
              allowHTML: true,
            });
          }}
          selectMirror={true}
          dayMaxEvents={true}
          eventClick={handleEventClick}
        />
      </Box>
      <BudgetModal />
    </>
  );
};

export default Calendar;
