import { PurchaseContext } from "./PurchaseContext";
import { useEffect, useMemo, useState } from "react";
import { PurchaseProps } from "./PurchaseContext";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseService } from "../../../../services/WorkshopPurchaseService";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

interface PurchaseProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface PurchasesResponse {
  data: WorkshopPurchase[];
  totalCount: number;
  pageSize: number;
}

export const PurchaseProvider = ({ children }: PurchaseProviderProps) => {
  const queryClient = useQueryClient();
  const purchaseService = new WorkshopPurchaseService();

  const [open, setOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState<
    undefined | WorkshopPurchase
  >(undefined);
  const [filters, setFilters] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);

  const serializedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  const purchasesQueryKey = ["purchases", serializedFilters, page, pageSize];
  const kanbanPurchasesInitialKey = ["kanbanPurchases", "initial"];
  const kanbanPurchasesFullKey = ["kanbanPurchases", "full"];

  const {
    data: initialKanbanPurchases,
    isLoading: loadingInitialKanbanPurchases,
  } = useQuery({
    queryKey: kanbanPurchasesInitialKey,
    queryFn: () => purchaseService.findAllPurchases(30),
    enabled: !!filters,
  });

  const { data: fullKanbanPurchases } = useQuery({
    queryKey: kanbanPurchasesFullKey,
    queryFn: () => purchaseService.findAllPurchases(),
    enabled: !!initialKanbanPurchases,
    staleTime: Infinity,
  });

  const kanbanPurchases = fullKanbanPurchases || initialKanbanPurchases || [];

  const {
    data: queryData,
    isLoading: loadingPurchases,
    isPlaceholderData,
  } = useQuery<PurchasesResponse, Error>({
    queryKey: purchasesQueryKey,
    queryFn: async () => {
      const response = await purchaseService.find({
        page,
        pageSize,
        filters,
      });
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const data = queryData ?? { data: [], totalCount: 0, pageSize: 20 };

  const { data: purchases, totalCount } = data;

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["purchases", filters, page + 1, pageSize],
      queryFn: () =>
        purchaseService.find({ page: page + 1, pageSize, filters }),
    });
  }, [page, filters, pageSize, queryClient, serializedFilters]);

  const replacePurchaseInCache = (
    purchaseId: number,
    updatedPurchase: WorkshopPurchase
  ) => {
    queryClient.setQueryData(
      purchasesQueryKey,
      (oldData: PurchasesResponse | undefined) => {
        if (!oldData) return oldData;

        const updatedPurchases = oldData.data.map((purchase) =>
          purchase.id === purchaseId ? updatedPurchase : purchase
        );

        return {
          ...oldData,
          data: updatedPurchases,
        };
      }
    );

    [kanbanPurchasesInitialKey, kanbanPurchasesFullKey].forEach((key) => {
      queryClient.setQueryData(
        key,
        (oldData: WorkshopPurchase[] | undefined) => {
          if (!oldData) return oldData;

          return oldData.map((purchase) =>
            purchase.id === purchaseId ? updatedPurchase : purchase
          );
        }
      );
    });
  };

  const updatePurchase = async (
    purchaseId: number,
    updatedPurchaseData: Partial<WorkshopPurchase>
  ) => {
    try {
      const updatedPurchase = await purchaseService.update(
        purchaseId,
        updatedPurchaseData
      );

      replacePurchaseInCache(purchaseId, updatedPurchase);

      if (selectedPurchase?.id === purchaseId) {
        setSelectedPurchase(updatedPurchase);
      }
    } catch (error) {
      console.error("Error updating purchase:", error);
    }
  };

  const purchaseContextValue: PurchaseProps = {
    open,
    setOpen,
    kanbanPurchases: kanbanPurchases || [],
    selectedPurchase,
    setSelectedPurchase,
    purchases,
    setPurchases: () => {},
    loading: loadingInitialKanbanPurchases || loadingPurchases,
    setLoading: () => {},
    totalCount,
    setFilters,
    pageSize,
    setPageSize,
    page,
    setPage,
    updatePurchase,
    isPlaceholderData,
    filters,
  };

  return (
    <PurchaseContext.Provider value={purchaseContextValue}>
      {children}
    </PurchaseContext.Provider>
  );
};
